import { template as template_83aefc4ca1a342debd546cb71cf28b0e } from "@ember/template-compiler";
const SidebarSectionMessage = template_83aefc4ca1a342debd546cb71cf28b0e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_9a97dc13b5674e64b48f2123175f435c } from "@ember/template-compiler";
const WelcomeHeader = template_9a97dc13b5674e64b48f2123175f435c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
